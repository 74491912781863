import { Global, css } from "@emotion/react"
import React from "react"

export const GlobalStyle = () => (
  <Global
    styles={css`
      /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
      @font-face {
        font-family: system;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        src: local(".SFNSText-Light"),
          local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"),
          local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"),
          local("DroidSans"), local("Tahoma");
      }
      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
      }

      a {
        text-decoration: none;
        position: relative;
        display: inline-block;
        z-index: 0;
        transition: height 0.2s ease;

        &:after {
    transition: background-color 0.2s ease, height 0.2s ease;

          content: "";
          position: absolute;
          display: block;
          height: 4px;
          width: calc(100% + 4px);
          left: 50%;
          transform: translateX(-50%);
          border-radius: 4px;
          bottom: 4px;
          z-index: -1;
        }
        &:hover {
          &:after {
            height: 70%;
          }
        }
      }

      /* HTML5 display-role reset for older browsers */

      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section {
        display: block;
      }

      body {
        line-height: 1;
        font-family: "system";
      }

      ol,
      ul {
        list-style: none;
      }

      blockquote,
      q {
        quotes: none;
      }

      blockquote {
        &:before,
        &:after {
          content: "";
          content: none;
        }
      }

      q {
        &:before,
        &:after {
          content: "";
          content: none;
        }
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
    `}
  />
)
