import React, {useContext} from "react"
import styled from "@emotion/styled"
import {themes} from "../theming/themes"
import {ThemeContext} from "../theming/ThemeContext"

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}

const FormStyled = styled.div`
  width: 80%;

  #result-message {
    text-align: center;
  }

  @media (max-width: 420px) {
    width: 100%;
  }
  margin: 3rem auto 0;

  form {
    display: none;
  }

  #form-switch {
    display: none;

    &:checked ~ form {
      display: block;
    }
  }

  h3 {
    cursor: pointer;
    text-align: center;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      width: 50%;
      background-color: ${({theme}) => themes[theme.name].button};
      left: 50%;
      transform: translateX(-50%);
      top: -1.33rem;
    }
  }

  label {
    margin-bottom: .5rem;
    display: flex;
    flex-direction: column;
  }

  input, textarea {
    padding: .5rem;
    background-color: transparent;
    color: ${({theme}) => themes[theme.name].foreground};
    border-color: ${({theme}) => themes[theme.name].accent};
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s ease, color 0.2s ease;
  }

  button {
    border: none;
    padding: .5rem;
    background-color: ${({theme}) => themes[theme.name].button};
    margin-top: .5rem;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    color: ${({theme}) => themes[theme.name].foreground};
  }

`

export const Form = () => {
    const [state, setState] = React.useState({})
    const [result, setResult] = React.useState('');

    const handleChange = e => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
                "form-name": form.getAttribute("name"),
                ...state,
            }),
        })
            .then(() => setResult('success'))
            .catch(() => setResult('error'))
    }
    const theme = useContext(ThemeContext)

    return (
        <FormStyled theme={theme}>

            {result === 'success' ? <p id="result-message">Sent!</p> : result === 'error' ?
                <p id="result-message">Something went wrong.</p> :
                <>
                    <input type="checkbox" id="form-switch"/>
                    <label htmlFor="form-switch">
                        <h3>Contact</h3>
                    </label>
                    <form
                        name="Personal Landing Page Form"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact"/>
                        <p hidden>
                            <label>
                                Don’t fill this out:{" "}
                                <input name="bot-field" onChange={handleChange}/>
                            </label>
                        </p>
                        <p>
                            <label>
                                Name
                                <input type="text" name="name" onChange={handleChange}/>
                            </label>
                        </p>
                        <p>
                            <label>
                                Email
                                <input type="email" name="email" onChange={handleChange}/>
                            </label>
                        </p>
                        <p>
                            <label>
                                Message
                                <textarea rows="6" name="message" onChange={handleChange}/>
                            </label>
                        </p>
                        <p style={{textAlign: 'right'}}>
                            <button type="submit">Send</button>
                        </p>
                    </form>
                </>}
        </FormStyled>
    )
}
