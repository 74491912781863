
import React, { useContext } from "react"
import styled from "@emotion/styled"
import { ThemeContext } from "../theming/ThemeContext"
import { themes } from "../theming/themes"
import pic from "../profile.jpg"

const PictureStyled = styled.div`
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => themes[theme.name].accent};
  transition: background-color .2s ease;
  margin: 0 auto;
  img {
    mix-blend-mode: multiply;
    filter: grayscale(100%) contrast(1.33);
      max-width: 200px;
  max-height: 200px;
  }
  &:after {
    background-color: ${({ theme }) =>
      theme.name === "dark"
        ? themes[theme.name].background
        : themes[theme.name].foreground};
    mix-blend-mode: lighten;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:hover {
    mix-blend-mode: unset !important;
    background-color: transparent;
    img {
      mix-blend-mode: unset !important;
      filter: none;
    }
    &:after {
      background-color: transparent;
      mix-blend-mode: unset !important;
    }
  }
`

export const Picture = () => {
  const theme = useContext(ThemeContext)

  return (
    <PictureStyled theme={theme}>
<img src={pic} alt="Temmuz Güzel"/>
    </PictureStyled>
  )
}
