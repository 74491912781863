export const themes = {
  light: {
    foreground: "#2d334a",
    background: "#fffffe",
    button: "#ffd803",
    accent: '#bae8e8'
  },
  dark: {
    foreground: "#fffffe",
    background: "#16161a",
    button: "#2cb67d",
    accent: '#7f5af0'
  },
}