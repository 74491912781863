import React from "react"
import { Layout } from "./components/layout.js"
import { GlobalStyle } from "./theming/global"
import { Main } from "./components/main"
import { ThemeProvider } from "./theming/ThemeContext"
const IndexPage = () => {
 
  return (
<ThemeProvider>
      <Layout>
        <GlobalStyle />
        <Main/>
      </Layout>
      </ThemeProvider>
  )
}
export default IndexPage

