import React from "react"
import styled from "@emotion/styled"
import {Picture} from "./picture"
import {Bio} from "./bio"
import {Form} from "./form"
import {Links} from "./links"

export const MainStyled = styled.main`
    grid-area: Main;
    max-width: 45rem;
    padding: 0 1rem 1rem;
    margin: 0 auto;
    line-height: 1.8;
`

export const Main = () => {
    return (
        <MainStyled>
            <Picture/>
            <Bio/>
            <Links/>
            <Form/>
        </MainStyled>
    )
}
