import React from "react"
import styled from "@emotion/styled"


const BioStyled = styled.p`
    margin: 2rem 0;
    text-align: center;
`;

export const Bio = () => {
    return (
        <BioStyled>
            Hey there, I'm a Frontend Developer based in Chemnitz, Germany. I work at <a target="_blank"
                                                                                         rel="noreferrer"
                                                                                         href="https://www.staffbase.com">Staffbase</a> 💙.
        </BioStyled>
    )
}