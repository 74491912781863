import React, { useContext } from "react"
import styled from "@emotion/styled"

import { ThemeContext } from "../theming/ThemeContext"
import { themes } from "../theming/themes"

const LinksStyled = styled.div`
    width: ${({more}) => more ? '80%' : '100%'};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        align-self: stretch;
        text-align: center;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            display: block;
            height: 2px;
            width: 50%;
            background-color: ${({theme}) => themes[theme.name].button};
            left: 50%;
            transform: translateX(-50%);
            top: -1rem;
        }
    }

    a {
        border: none;
        text-align: center;
        background-color: ${({theme}) => themes[theme.name].button};
        margin-top: 1rem;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        padding: 0 .75rem;
    }

    p {
        margin-top: 1rem;
        font-size: 12px;
        font-style: italic;
        color: ${({theme}) => themes[theme.name].foreground};

    }
`

export const Links = () => {
  const theme = useContext(ThemeContext)

  return (
      <LinksStyled theme={theme}>
        <h3>Apps</h3>
        <a rel="noreferrer" target="_blank" href="https://meeting-cost-counter.vercel.app">Meeting Cost Calculator</a>
        <a rel="noreferrer" target="_blank" href="https://sans-lmsms.vercel.app">Sans LMSMS</a>
          <p>Disclaimer: These apps are satire. They do not target or mock any individuals.</p>
      </LinksStyled>
  )
}
