
import React, { useState } from "react"
import styled from "@emotion/styled"
import { Switch } from "./switch"
import { themes } from "../theming/themes"

const HeaderStyled = styled.header`
  grid-area: Header;
  display: flex;
  justify-content: space-between;
  @media (max-width: 420px) {
        justify-content: flex-end;
      }
  align-items: flex-start;
  a:after {
    content: none;
  }
  h1 {
    background-color: ${({ theme }) => themes[theme.name].button};
    padding: 1rem;
    border-radius: 500px;
  }
  & > span {
      @media (max-width: 420px) {
        display: none;
      }
  }
`
const emojis = [
  "🔊",
  "👹",
  "👺",
  "💯",
  "🤯",
  "💀",
  "✊🏼",
  "🤟🏼",
  "👋🏼",
  "🧠",
  "👨🏻‍💻",
  "🕶",
  "🦑",
  "🌚",
  "🔥",
  "🥊",
  "🎧",
  "💡",
  "🎉",
  "💬",
]

export const Header = ({ theme }) => {
  const [emoji, setEmoji] = useState(
    emojis[Math.floor(Math.random() * emojis.length)]
  )

  const handleHover = () => {
    const newEmojis = [...emojis].filter(e => e !== emoji)
    const randomEmoji = newEmojis[Math.floor(Math.random() * newEmojis.length)]
    setEmoji(randomEmoji)
  }

  return (
    <HeaderStyled theme={theme}>
      <span onMouseEnter={handleHover}>
        <h1>{emoji}</h1>
      </span>
      <Switch />
    </HeaderStyled>
  )
}
