import React, { useContext } from "react"
import styled from "@emotion/styled"
import { ThemeContext } from "../theming/ThemeContext"
import { themes } from "../theming/themes"

const SwitchStyled = styled.div`
  input {
    display: none;
  }
  label {
    color: ${({ theme }) => themes[theme.name].background};
    cursor: pointer;
  }
`

export const Switch = () => {
  const theme = useContext(ThemeContext)
  return (
    <SwitchStyled theme={theme}>
      <input
        onChange={() =>
          theme.updateTheme(theme.name === "light" ? "dark" : "light")
        }
        type="checkbox"
        id="switch"
        checked={theme.name === "dark"}
      />
      <label htmlFor="switch">
        {theme.name === "light" ? "🌗" : "🌓 "}
      </label>
    </SwitchStyled>
  )
}
