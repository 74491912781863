/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext } from "react"
import styled from "@emotion/styled"
import { Header } from "./header"
import { Footer } from "./footer"
import { ThemeContext } from "../theming/ThemeContext"
import { themes } from "../theming/themes"

const LayoutStyled = styled.div`
  background-color: ${({ theme }) => themes[theme.name].background};
  &,
  & a {
    color: ${({ theme }) => themes[theme.name].foreground};
    transition: background 0.2s ease, color 0.2s ease;
  }
  a:after {
    background-color: ${({ theme }) => themes[theme.name].accent};
  }
  min-height: calc(100vh - 2rem);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  gap: 0 0;
  grid-template-areas:
    "Header"
    "Main"
    "Footer";
  padding: 1rem 2rem;
`

export const Layout = ({ children }) => {
  const theme = useContext(ThemeContext)
  return (
    <LayoutStyled theme={theme}>
      <Header theme={theme} />
      {children}
      <Footer />
    </LayoutStyled>
  )
}
