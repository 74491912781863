import React from "react"
import styled from "@emotion/styled"

const FooterStyled = styled.footer`
  grid-area: Footer;
  line-height: 1.8;
  font-size: 12px;
  text-align: right;
  @media (max-width: 420px) {
        text-align: center;
      }
`

export const Footer = () => {
  return (
    <FooterStyled>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a target="_blank" rel="noreferrer"  href="https://reactjs.org/">React</a>
      {`, `}
      <a target="_blank" rel="noreferrer"  href="https://emotion.sh/">Emotion</a>
      {`, `}
      <a target="_blank" rel="noreferrer"  href="https://www.netlify.com">Netlify</a>
      {` `}
      and lots of emojis.
    </FooterStyled>
  )
}
